import React from "react";
// images
import ImgBanner from "../assets/nivusneon8.png";
// resume
import resume from "../assets/Gyselman-Anthony.pdf";
// icons
import { FaGithub, FaInstagram, FaLinkedinIn } from "react-icons/fa";
// type animation
import { TypeAnimation } from "react-type-animation";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";


const Banner = () => {
  return (
    <section
      className="min-h-[85vh] lg:min-h-[78vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          {/* text */}
          <div className="flex-1 text-center font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
            >
              GYSELMAN <span>ANTHONY</span>
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]"
            >
              <span className="text-white mr-4">I am a</span>
              <TypeAnimation
                sequence={["Dev Front-End", 2000, "Designer", 2000]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-w-lg mx-auto lg:mx-0 text-[23px] font-bold text-gradient btn-link"
            >
              Software Engineer Graduate @Adrar
            </motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
            >
              <a href="#contact" className="btnBanner btn-lg">
                Contact me
              </a>
              <a href="#work" className="text-gradient btn-link">
                My Portfolio
              </a>
              <a href={resume} download="Gyselman_Anthony_Resume" className="text-gradient btn-link">
                Resume
              </a>
            </motion.div>
            {/* socials */}
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-1"
            >
              <a href="https://github.com/AGyselman?tab=repositories" target="_blank" rel="noreferrer" className="icon1">
                <FaGithub />
              </a>
              <a href="https://www.instagram.com/anthony_gys/" target="_blank" rel="noreferrer" className="icon2">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/in/anthony-gyselman-8b250215a/" target="_blank" rel="noreferrer" className="icon3">
                <FaLinkedinIn />
              </a>
            </motion.div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="hidden lg:flex flex-1 max-w-[350px] lg:max-w-[1000px]"
          >
            <img src={ImgBanner} alt="imgbanner" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
