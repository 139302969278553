import React from "react";
import moi from '../assets/Dijin.png';
// icons
import { FaTimes } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaDice } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { FaJsSquare } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";

const Modal2 = ({ open, onClose, open2, onClose2 }) => {
  if (!open2) return null;

  return (
    <div onClick={onClose2} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <img className="imgmodal" src={moi} alt="imgbanner" />
        <div className="modalRight">
          <p onClick={onClose2} className="closeBtn">
            <FaTimes />
          </p>
          <div className="content">
          <a href="#work" className="text-gradient btn-link">
                The Carpate Show
              </a>
            <p>
            Creation of a website for the company <b>Gajitimas</b>. Publisher of <b>independent board games</b>.
            After a well provided <b>specifications</b> that we have well established <b>together</b>, I used this one to develop the website.
            This page of the site gathers the various <b>factions</b> as well as the <b>characters</b> playable during the game.
            </p>
            <div className="socialModal">
              <FaGithub />
                <a href="https://github.com/AGyselman/Gajitimas-sort-card" target="_blank" rel="noreferrer" className="text-gradient btn-link">
                Github Link
                </a>
              <FaDice />
            </div>
            <div className='iconWork'>
             <FaHtml5/> <FaCss3 /> <FaJsSquare/> <SiAdobephotoshop/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
