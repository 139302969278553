import React from "react";
// images
import Logo from "../assets/logotest9.png";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";

const Header = () => {
  return (
    <header className="py-8 ">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* logo */}
          {/* <a href="#">
            <img className="logo" src={Logo} alt="Logo" />
          </a> */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="hidden lg:flex flex-1 lg:max-w-[110px] cursor-pointer"
          >
            <img className="logo" src={Logo} alt="Logo" />
          </motion.div>
          {/* button */}
          {/* <button className="btn btn-sm">Work with me</button> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
