import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
//motion
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
//icon
import { FaHandshake } from "react-icons/fa";
//alert email
import Swal from 'sweetalert2'
// resume
import resume from "../assets/Gyselman-Anthony.pdf";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_frfzeyq', 'template_yt32z89', form.current, '7eFcR5A7b3wTTmK7D')
      .then((result) => {
          console.log(result.text);
          console.log("Message sent")
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  const Alert = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      background: 'linear-gradient(90deg, rgba(35,35,36,1) 0%, rgba(50,50,52,1) 35%, rgba(40,39,39,1) 100%);',
      title: 'Your message has been sent! 📧 Thank you!',
      showConfirmButton: false,
      timer: 2500
    })
  }

  return (
    <section className="py-16 lg:section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div className="contact">
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                Get in touch 💬
              </h4>
              <h2 className="text-[25px] lg:text-[40px] leading-none mb-12 text-gradient">
                Your opinion <br /> counts !
              </h2>
              <p className="btn-link tracking-wide lg:text-[23px] mb-12">Do not hesitate to give me feedback on my portfolio whether it is
               positive or negative, as long as the remarks are constructive.
               This will help me improve my portfolio in the future. Also I am looking for a job so I am open to any proposal.
              </p>
              <a href={resume} download="Gyselman_Anthony_Resume" className="text-gradient btn-link">
                Download resume
              </a>
              <p className="text-[25px] lg:text-[23px] text-gradient">Thanks in advance.<br/>
              Gyselman Anthony</p>
              <FaHandshake/>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl flex flex-col  pb-24 p-6 items-start"
            ref={form} onSubmit={sendEmail}>
            <label className="text-gradient">Your Name</label>
            <input type="text" name="user_name" className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all" />
            <label className="text-gradient pt-5">Your Email</label>
            <input type="email" name="user_email" className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all" />
            <label className="text-gradient pt-5">Your Message</label>
            <textarea name="message" className="bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12"/>
            <input onClick={Alert} type="submit" value="Send Message" className="btn2 btn-lg pb-[15px] w-25 cursor-pointer" />
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
