import React from "react";
// icon
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// services data
const services = [
  {
    name: "UI/UX Design",
    description:
      "Passionate about design, I practice today to design user interfaces that are optimal, pleasant and easy to use, whether alone or with a team, I bring the maximum on each project.",
    // link: "Learn more",
  },
  // {
  //   name: "Development",
  //   description:
  //     "Autem quam paulo ex perstrinxi) esse ii etiam etiam maxime autem virium amicitiarum dicere firmitatis locum beati magis firmitatis adiumentique.",
  //   link: "Learn more",
  // },
  {
    name: "Technologie & Skills",
    description:
      "HTML5 / CSS3, JavaScriptES6, ReactJS / React Native, Vue.Js, Bootstrap / Tailwind CSS, Node.Js, Looping (MCD,MLD), MySQL WorkBench, MVC, Agile, Scrum, Github.",
    // link: "Learn more",
  },
  {
    name: "Philosophy",
    description:
      "I see web development as a vehicle to facilitate communication and enhance interactions. When working on a project, i like to plan ahead strategically, find simple yet well-thought solutions.",
    // link: "Learn more",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text & image */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-bottom bg-no-repeat mix-blend-lighten mb-20 lg:mb-0"
          >
            <h2 className="h2 text-accent mb-6">What I Do.</h2>
            <h3 className="h3 max-w-[455px] mb-16 text-gradient">
              Modern user interface development
            </h3>
            <p className="text-justify btn-link tracking-wide lg:text-[23px] mb-12 leading-10">I believe web design can be more diverse and<br/>  inspiring,
             with a mission to present the possibilities <br/>of web design. I'am pursuing new expressions<br/> throught experiments and thought.</p>
            {/* <a href="#contact" className="btn btn-lg">
                Contact me
              </a> */}
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            {/* services list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, link } = service;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
                      {/* <a
                        href="#"
                        className="btnp btn w-9 h-9 mb-[42px] flex justify-center items-center"
                      >
                        <BsArrowUpRight className="pb-[10px] h-9" />
                      </a> */}
                      {/* <a href="#" className="text-gradient text-sm">
                        {link}
                      </a> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
