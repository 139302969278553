import React from 'react';
import moi from '../assets/bankist.png';
// icons
import { FaTimes } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { FaJsSquare } from "react-icons/fa";

import { FcComboChart } from "react-icons/fc";
import { FcAdvance } from "react-icons/fc";




const Modal3 = ({open, onClose, open3, onClose3}) => {
if(!open3) return null;

  return (
    <div onClick={onClose3} className='overlay'>
        <div onClick={(e) => {
            e.stopPropagation()
        }} className='modalContainer'>
            <img className='imgmodal3' src={moi} alt="imgbanner" />
            <div className='modalRight'>
             <p onClick={onClose3} className='closeBtn'><FaTimes/></p>
             <div className='content'>
             <a href="#work" className="text-gradient btn-link">
                Bankist App
              </a>
              <p>
              Creation of a <b>transaction management tool banking application</b>. Following an online course with <b>Udemy</b> I followed step by
               step the process to get to design this <b>banking application</b>. Allows users to <b>manage</b> their <b>bank account</b>.
                You can request a <b>loan</b> from the bank (<b>virtual</b>), transfer money from <b>account to account</b> and see them appear in your <b>current balance</b>. 
            </p>
                 <div className="socialModal">
                  <FaGithub />
                    <a href="https://github.com/AGyselman/Bankist-App" target="_blank" rel='noreferrer' className="text-gradient btn-link">
                     Github Link
                    </a>
              <FcComboChart /> 
            </div>
            <div className='iconWork'>
             <FaHtml5/> <FaCss3 /> <FaJsSquare/>
            </div>
             </div>
            </div>
        </div>
    </div>
  )
}

export default Modal3