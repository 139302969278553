import React from "react";
import moi from "../assets/Cloud.png";
// icons
import { FaTimes } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaHandPointRight } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3 } from "react-icons/fa";
import { FaJsSquare } from "react-icons/fa";
import { FaVuejs } from "react-icons/fa";



const Modal = ({ open, onClose, open2, onClose2 }) => {
  if (!open) {
    return null;
  }

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer">
        <img className="imgmodal1" src={moi} alt="imgbanner" />
        <div className="modalRight">
          <p onClick={onClose} className="closeBtn">
            <FaTimes />
          </p>
          <div className="content">
          <a href="#work" className="text-gradient btn-link">
              Final Fantasy VII Game
              </a>
            <p>
            Creation of a turn-based <b>mini game</b> on the theme of <b>Final Fantasy VII</b>. Realized during my year of training, this project integrates the <b>VueJS Framework</b>.
             In this <b>mini game</b> you play as <b>Cloud Strife</b> in a fight against <b>Barret Wallace</b>. Attack with a <b>normal attack</b> or a <b>special attack</b>, <b>heal yourself</b>, in short <b>have fun</b>.</p>
            <div className="socialModal">
              <FaGithub/>
                <a href="https://github.com/AGyselman/FFVII-game-turn-by-turn" target="_blank" rel="noreferrer" className="text-gradient btn-link">
                Github Link
                </a>
              <FaHandPointRight />
            </div>
            <div className='iconWork'>
            <FaHtml5/> <FaCss3 /> <FaJsSquare/> <FaVuejs/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
