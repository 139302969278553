import React, { useState } from "react";

//motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// img
import Img1 from "../assets/FFVII.gif";
import Img2 from "../assets/carparte.gif";
import Img3 from "../assets/Bankist.gif";
// icon
import { BsArrowUpRight } from "react-icons/bs";
//modal
import Modal from "./Modal";
import Modal2 from "./Modal2";
import Modal3 from "./Modal3";

const Work = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);


  return (
    <section className="section" id="work">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-14 mb-10 lg:mb-0"
          >
            {/* text */}
            <div>
              <h2 className="h2 leading-tight text-accent">
                My Latest <br />
                Work.
              </h2>
              <p className="btn-link tracking-wide lg:text-[23px] mb-12 leading-10">
                Here's a selection of my most recent project.
                <br />
                <a href="#contact" className="text-gradient btn-link">
                  Reach out
                </a>{" "}
                if you have any questions!
              </p>
              <a href="https://github.com/AGyselman?tab=repositories" target="_blank" rel="noreferrer" className="btn2 btn-lg pb-[15px] w-5">
                View all Projects
              </a>
            </div>
            {/* image */}
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl ">
              {/* overlay*/}
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              {/* img */}
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={Img1}
                alt="image1"
              />
              {/* pretitle */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">UI/UX Design</span>
              </div>
              {/* Modal 1 */}
              <div>
                <button
                  onClick={() => {setOpenModal(true) ; setOpenModal2(false) ; setOpenModal3(false) }}
                  className="btn w-8 h-8 mb-[45px] flex justify-center items-center absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-40"
                >
                  <BsArrowUpRight className="pb-[15px] h-10" />
                </button>
                <Modal open={openModal} onClose={() => setOpenModal(false)} open2={openModal2} onClose2={() => setOpenModal2(false)} />
              </div>
              {/* title */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white">Final Fantasy VII Game</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-10"
          >
            {/* image */}
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              {/* overlay */}
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              {/* img */}
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={Img2}
                alt="image1"
              />
              {/* pretitle */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">UI/UX Design</span>
              </div>
              {/* Modal 2 */}
              <div>
                <button
                  onClick={() => {setOpenModal(false) ; setOpenModal2(true) ; setOpenModal3(false)}}
                  className="btn w-8 h-8 mb-[45px] flex justify-center items-center absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50"
                >
                  <BsArrowUpRight className="pb-[15px] h-10" />
                </button>
                <Modal2 open={openModal} onClose={() => setOpenModal(false)} open2={openModal2} onClose2={() => setOpenModal2(false)}  />
              </div>
              {/* title */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white">The Carpate Show</span>
              </div>
            </div>

            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              {/* overlay */}
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              {/* img */}
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={Img3}
                alt="image1"
              />
              {/* pretitle */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">UI/UX Design</span>
              </div>
              {/* Modal 3 */}
              <div>
                <button
                  onClick={() => {setOpenModal(false) ; setOpenModal2(false) ; setOpenModal3(true)}}
                  className="btn w-8 h-8 mb-[45px] flex justify-center items-center absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50"
                >
                  <BsArrowUpRight className="pb-[15px] h-10" />
                </button>
                <Modal3 open3={openModal3} onClose3={() => setOpenModal3(false)} />
              </div>
              {/* title */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white">Bankist App</span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
